
/**
 * Retrieves a target object with indices from the
 * paged structure
 *
 * @param {Array} pages The current pages
 * @param {String} externalId The external ID to search for
 * @returns {Object}
 */
const getObjectFromPages = (pages, externalId) => {
    for (const pageIndex in pages) {
        const { objects } = pages[pageIndex];
        const objectIndex = objects?.findIndex(item => (
            item.external_id === externalId
        ));
        if (objectIndex > -1) {
            return {
                pageIndex,
                objectIndex,
                object: objects[objectIndex]
            };
        }
    }
    return {};
};

export default getObjectFromPages;