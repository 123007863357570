import { pick } from 'lodash';

const EDITABLE_FIELDS = ['budget', 'name', 'status'];

/**
 * Merges the original object together with updated fields
 * from the new one
 *
 * @param {Object} oldObject
 * @param {Object} newObject
 * @returns {Object}
 */
const mergeEditedObject = (oldObject, newObject) => {
    const editedFields = pick(newObject, EDITABLE_FIELDS);
    return {
        ...oldObject,
        ...editedFields
    };
};

export default mergeEditedObject;