<template>
    <styled-tooltip class="totals-tooltip">
        <template #content>
            All metric totals represent both active
            &amp; inactive {{ type }}
        </template>
        <icon
            class="message-icon"
            name="question-circle"
            size="12"
            color="gray" />
    </styled-tooltip>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledTooltip from '@/components/globals/StyledTooltip';

export default {
    components: {
        Icon,
        StyledTooltip
    },
    props: {
        type: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.message-icon {
    display: block;
}
</style>