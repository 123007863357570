<template>
    <span>
        <span
            class="circle"
            :class="{
                [value.toLowerCase()]: true,
                'delivered': !value
            }" />
        <div v-outside-click="closeFilter">
            <a
                class="status-filter-label"
                @click="open = !open">
                <span>{{ currentLabel }}</span>
                <icon
                    size="10"
                    class="ml-2"
                    :class="{
                        'status-filter-icon': true,
                        'status-filter-icon-open': open
                    }"
                    name="chevron-down" />
            </a>
            <span
                v-if="open"
                class="search-filter">
                <form @submit.prevent="() => null">
                    <styled-radio
                        v-for="option in options"
                        :key="option.value"
                        class="search-field"
                        :value="value"
                        :input-value="option.value"
                        :label="option.label"
                        inline
                        @input="$emit('input', option.value)" />
                </form>
            </span>
        </div>
    </span>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledRadio from '@/components/globals/StyledRadio';
import { PLATFORM_STATUS_OPTIONS } from '@/helpers/globals';

export default {
    components: {
        Icon,
        StyledRadio
    },
    props: {
        value: {
            type: String,
            required: true
        },
        count: {
            type: Number,
            default: undefined
        }
    },
    data() {
        return {
            open: false,
            options: [...PLATFORM_STATUS_OPTIONS]
        };
    },
    computed: {
        currentLabel() {
            let label = this.options.find(option => option.value === this.value)?.label;
            if (this.count) {
                label = `${this.count} ${label}`;
            }
            return label;
        }
    },
    methods: {
        closeFilter() {
            this.open = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.search-field {
    display: block;
}

.search-filter {
    position: absolute;
    width: 130px;
    margin-top: 24px;
    margin-left: -80px;
    padding: 15px;
    background-color: white;
    box-shadow: 1px 0px 17px 0px rgba(0,0,0,0.35);
    z-index: 9999;
    label {
        display: block;
        text-align: left;
        margin-bottom: 8px;
        font-weight: 600;
        color: #bbbbbb;
        font-size: 13px;
        display: table;
        text-transform: uppercase;
    }
    input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
        &:checked {
            & ~ .styled-radio-checkmark {
                background-color: $blue-btn;
                border-color: $blue-btn;
                &::after {
                    display: block;
                }
            }
        }
    }
}
.status-filter-label {
    text-transform: uppercase;
}
.status-filter-icon {
    vertical-align: middle;
    transition: transform 0.2s ease-in-out;
}
.status-filter-icon-open {
    transform: rotate(180deg);
}
</style>
